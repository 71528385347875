import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./analys.css";

function AnalysContainer() {
  var Pic4 = require("../../assets/analys.jpg");
  return (
    <Container className="analys-content-container">
      <Row xs={1} sm={1} md={2} style={{ justifyContent: "center" }}>
        <Col className="">
          <h2>Analys, strategi & beslut</h2>
          <p>
            Att outsourca ekonomichefs/controller-rollen kan vara en effektiv
            och flexibel lösning i stället för att anställa. Anställa innebär
            ett stort ansvar och kanske arbetsuppgifterna inte räcker för en
            heltid. Möjligtvis finns det redan en ekonomiassistent eller
            administrationsansvarig som behöver ett bollplank och ett extra
            stöd?
          </p>
          <p>
            Företaget kan lättare justera uppdragstiden för en konsulterande
            ekonomichef vartefter behoven ökar eller minskar. Konsulten är
            ständigt uppdaterad kompetensmässigt och expert på området.{" "}
          </p>
          <p>
            Målet är att ni som kund ska få mer effekt för insatsen och inte
            behöva stå med en outnyttjad lönekostnad.
          </p>
          <ul>
            <li>Ledningsgruppsarbete</li>
            <li>Strategi</li>
            <li>Analys</li>
            <li>Uppföljning av finansiell data</li>
            <li>Affärsplan</li>
            <li>Nyckeltal</li>
            <li>Verksamhetsutveckling</li>
          </ul>
        </Col>
        <Col>
          <img src={Pic4} alt="avatar" className="logga-analys" />
        </Col>
      </Row>
    </Container>
  );
}

export default AnalysContainer;
