import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./lon.css";

function LonHrContainer() {
  var Pic4 = require("../../assets/lonhr.jpg");

  return (
    <Container className="lon-content-container">
      <Row xs={1} sm={1} md={2} style={{ justifyContent: "center" }}>
        <Col>
          <h2>Lön & HR</h2>
          <p>
            Att hålla koll på regler och lagar kring personal, betala ut löner
            och registrera arbetsgivardeklarationer kan kännas krångligt och
            tidskrävande. Det är samtidigt ett av de viktigaste flödena som
            måste fungera för er viktigaste tillgång: Era anställda.
          </p>

          <p>
            Känn er säkra med att lämna över löneadministration till erfarna
            konsulter på FlowEK. Tillsammans skapar vi de bästa
            förutsättningarna för er som seriös och trygg arbetsgivare.
          </p>
          <ul>
            <li>Löneadministration</li>
            <li>Löneutbetalningar</li>
            <li>Reseräkningar & Utlägg</li>
            <li>Arbetsgivardeklaration</li>
            <li>Stöd vid hantering av kollektivavtal</li>
            <li>
              Stöd vid hantering av frågor kring föräldraledighet,
              sjukskrivning, förmåner mm.
            </li>
            <li>Rapportering till exempelvis SCB, Fora m.fl.</li>
            <li>Rapportering till pensionsbolag</li>
          </ul>
        </Col>
        <Col>
          <img src={Pic4} alt="avatar" className="logga-lonhr" />
        </Col>
      </Row>
    </Container>
  );
}

export default LonHrContainer;
