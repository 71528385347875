import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./radgivning.css";

function RadgivningContainer() {
  var Pic4 = require('../../assets/affarsradgivning.jpg');
  return (
    <Container className="radgivning-content-container">
      <Row xs={1} sm={1} md={2} style={{ justifyContent: "center" }}>
        <Col>
          <h2>Affärsrådgivning</h2>
          <p>
            Vill du göra förändringar i ditt bolag men inte vet hur du ska ta
            nästa steg? FlowEK fungerar som en stödfunktion, från identifiering
            av behov till analys och action, gällande era administrativa
            processer.
          </p>
          <ul>
            <li>Månadsbokslut</li>
            <li>Finansiell rapportering</li>
            <li>Kassaflödesanalys/Likviditetsplanering</li>
            <li>Budgetering</li>
            <li>Systemrådgivning</li>
            <li>Ekonomi- och skatterådgivning för delägare</li>
          </ul>
      
        </Col>
        <Col>
          <img src={Pic4} alt="avatar" className="logga-radgivning" />
        </Col>
      </Row>
    </Container>
  );
}

export default RadgivningContainer;
