import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Homeinfocontainer from "./HomeInfoContainer";
import background from '../../assets/shutterstock_1708231747.jpg';
import "./home.css";

function Home() {


  return (
    <section className="bgimg">
      <Container className="home-content" >
        <Row xs={1} sm={1} md={1} lg={2} style={{ justifyContent: "left" }}>
          <Col className="home-header" >
            <div className="heading" >
              Fokusera på det du är bra på. Flowek frigör din tid för
              värdeskapande aktiviteter.
            </div>
            <div style={{ padding: 0, textAlign: "left" }}>
              <div className="heading-name ">
                Behöver du hjälp med ditt bolags redovisning och
                löneadministration? Kanske du vill ta ditt bolag till nästa nivå
                och behöver affärsrådgivning och stöd för att bygga upp ditt
                bolags administrativa processer och rutiner? Eller varför inte
                låta oss vara hela din ekonomiavdelning?
              </div>
            </div>
            <div className="home-about-body">
              Flowek är en modern redovisningsbyrå som erbjuder ett tryggt,
              personligt och utvecklande stöd för dig och ditt bolag.
            </div>
          </Col>
          <Col style={{ paddingBottom: "200px" }}></Col>
        </Row>
      </Container>
      {/* <Homeinfocontainer
        headertext="Interim"
        information="Flowek är en modern redovisningsbyrå som erbjuder ett tryggt,
              personligt och utvecklande stöd för dig och ditt bolag.."
        isRight={true}
        route={"/interim"}
      ></Homeinfocontainer>
      <Homeinfocontainer
        headertext="Redovisning"
        information="Flowek är en modern redovisningsbyrå som erbjuder ett tryggt,
              personligt och utvecklande stöd för dig och ditt bolag.."
        isRight={false}
        route={"/redovisning"}
      ></Homeinfocontainer>
      <Homeinfocontainer
        headertext="Lön och HR"
        information="Flowek är en modern redovisningsbyrå som erbjuder ett tryggt,
              personligt och utvecklande stöd för dig och ditt bolag."
        isRight={true}
        route={"/lonhr"}
      ></Homeinfocontainer>
      <Homeinfocontainer
        headertext="Analys, strategi och beslut"
        information="Flowek är en modern redovisningsbyrå som erbjuder ett tryggt,
              personligt och utvecklande stöd för dig och ditt bolag."
        isRight={false}
        route={"/analys"}
      ></Homeinfocontainer> */}
    </section>
  );
}

export default Home;
