import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import InterimContainer from "./components/Interim/InterimContainer";
import RadgivningContainer from "./components/AffarsRadgivning/RadgivningContainer";
import AnalysContainer from "./components/Analys/AnalysContainer";
import LonHrContainer from "./components/LonHr/LonContainer";
import RedovisningsContainer from "./components/Redovisning/RedovisningsContainer";
import VisionsContainer from "./components/Vision/VisionContainer";
import AuktoContainer from "./components/Auktoriserad/AuktoriseradContainer";
import ContactContainer from "./components/Contact/Contact";
import Footer from "./components/Footer";
import useWindowSize from './WindowSize.js'
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  const size = useWindowSize();
  return (
    <Router>
    <Navbar />
    
      <div className="App" id={load ? "no-scroll" : "scroll"}>
      
          
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/redovisning" element={<RedovisningsContainer />} />
          <Route path="/interim" element={<InterimContainer />} />
          <Route path="/analys" element={<AnalysContainer />} />
          <Route path="/radgivning" element={<RadgivningContainer />} />
          <Route path="/lonhr" element={<LonHrContainer />} />
          <Route path="/vision" element={<VisionsContainer />} />
          <Route path="/auktoriserad" element={<AuktoContainer />} />
          <Route path="/kontakt" element={<ContactContainer />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        
      </div>
      {size.width > 1200 && <Footer/>}
      
   
    </Router>
    
  );
}

export default App;
