import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./interim.css";

function InterimContainer() {
  var Pic = require("../../assets/interim.jpg");
  return (
    <Container className="interim-content-container">
      <Row xs={1} sm={1} md={2} style={{ justifyContent: "center" }}>
        <Col>
          <h2>Interim</h2>
          <p>
            Behöver er verksamhet extra resurser till ekonomiavdelningen…<br></br>{" "}
            <br></br>…under perioder med hög arbetsbelastning? <br></br>…vid
            sjukskrivningar? <br></br>…eller mellan anställningar? <br></br><br></br>Då
            kan en interimslösning vara lämplig. <br></br><br></br>Interim betyder ”tills vidare”
            och det finns som nämnt ovan tillfällen då man som bolag behöver
            snabb och flexibel hjälp från en extern part under en begränsad tid.
            Att slippa huvudbry med nyanställning och få in rätt kompetensen
            där- och när behovet finns kan vara av största vikt för att inte
            tappa fart i sin affär. FloweEK’s välutbildade team står redo att
            sätta sig in i rollen snabbt och stötta ditt bolag både på kort- och
            lång sikt med samtliga tjänster.
          </p>
          
          <p>
            Då kan en interimslösning vara lämplig. FloweEK’s välutbildade team
            står redo att stötta ditt bolag när det behövs.
          </p>
        </Col>
        <Col>
          <img src={Pic} alt="avatar" className="logga-interim" />
        </Col>
      </Row>
    </Container>
  );
}

export default InterimContainer;
