import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../style.css";

function Footer() {
  return (
    <div className="footer-container" >
    <Container className="footer">
      <Row xs={3} sm={3} md={3} >
        <Col style={{ width: "150px"}}>
          <h2>Våra tjänster</h2>
          <Link className="footer-link" to="/lonhr">
            <h3>Lön och HR</h3>
          </Link>
          <Link className="footer-link" to="/redovisning">
            <h3>Redovisning</h3>
          </Link>
          <Link className="footer-link" to="/radgivning">
            <h3>Affärsrådgivning</h3>
          </Link>
          <Link className="footer-link" to="/interim">
            <h3>Interim</h3>
          </Link>
          <Link className="footer-link" to="/analys">
            <h3>Analys, strategi & beslut</h3>
          </Link>
        </Col>
        <Col style={{width: "150px"}}>
          <h2>Om oss</h2>
          <Link className="footer-link" to="/vision">
            <h3>Vision</h3>
          </Link>
          <Link className="footer-link" to="/auktoriserad">
            <h3>Auktoriserad byrå</h3>
          </Link>
        </Col>
        <Col style={{ width: "100px"}}>
          <h2>Kontakt</h2>
          <Link className="footer-link" to="/kontakt">
            <h3>Kontakta oss</h3>
          </Link>
          <a
            href="https://www.linkedin.com/company/flowek/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h3 className="footer-link">LinkedIn</h3>
          </a>
          <a
            href="https://instagram.com/flowek.se?igshid=NTc4MTIwNjQ2YQ==/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h3 className="footer-link">instagram</h3>
          </a>
          <a
            href="https://www.facebook.com/profile.php?id=100093714581285&mibextid=LQQJ4d/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <h3 className="footer-link">Facebook</h3>
          </a>
        </Col>
        
      </Row>
      
    </Container></div>
  );
}

export default Footer;
