import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./redovisning.css";

function RedovisningsContainer() {
  var Pic4 = require("../../assets/redovisning.jpg");
  return (
    <Container className="redovisning-content-container">
      <Row xs={1} sm={1} md={2} style={{ justifyContent: "center" }}>
        <Col>
          <h2>Redovisning</h2>
          <p>
            Med personlig service och förmåga att kombinera den med effektiva
            digitala lösningar, strävar vi efter att skapa gynnsamma
            flöden för redovisning inom ditt företag. Vårt första steg är att
            samarbeta nära med dig och ditt team för att förstå de unika behoven
            och målen för ert bolag. Vi genomför en noggrann analys av er
            befintliga redovisningsprocess och identifierar områden där
            effektiviteten kan förbättras och kostnader minskas.
          </p>
          <ul>
            <li>Löpande bokföring</li>
            <li>Kund- och leverantörsreskontra</li>
            <li>Kvittoredovisning</li>
            <li>Momsredovisning</li>
            <li>Projektredovisning</li>
            <li>Avstämningar</li>
            <li>Månadsrapportering</li>
          </ul>
          <h2>Årsavslut</h2>

          <ul>
            <li>Årsbokslut</li>
            <li>Årsredovisning</li>
            <li>Inkomstdeklaration</li>
          </ul>
        </Col>
        <Col>
          <img src={Pic4} alt="avatar" className="logga-redovisning" />
        </Col>
      </Row>
    </Container>
  );
}

export default RedovisningsContainer;
