import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./contact.css";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";

function ContactContainer() {
  var Pic1 = require("../../assets/matilda_profile.jpg");
  return (
    <Container className="contact-section">
      <h1>Kontakta oss</h1>
      <Row xs={1} sm={1} md={1} lg={1} style={{ paddingTop: "40px" }}>
        <Col>
          <img src={Pic1} alt="avatar" className="profil-bild" />
          <h3 className="contact h3">Matilda Frisk, 072-172 31 59 </h3>
        </Col>
      </Row>
      <Row style={{ paddingTop: "40px" }}>
        <h3>
          <a href="mailto:info@flowek.se">info@flowek.se</a>
        </h3>
      </Row>
    </Container>
  );
}
export default ContactContainer;
