import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Button } from "react-bootstrap";
import logo from "../assets/logoklar.png";
import useWindowSize from '../WindowSize.js'
import "../style.css";

function NavBar() {
  const [expand, setExpand] = useState(false);
  const [navColour, setNavColour] = useState(false);
  const navigate = useNavigate();

  const kontakt = () => {
    navigate("/kontakt");
    closeNav();
  };

  const scrollHandler = () => {
    if (window.scrollY >= 20) {
      setNavColour(true);
    } else {
      setNavColour(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  const toggleExpand = () => {
    setExpand(!expand);
  };

  const closeNav = () => {
    setExpand(false);
  };
  const size = useWindowSize();

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="xl"
      className={navColour ? "sticky" : "navbar"}
    >
      <Container >
        <Navbar.Brand href="/" className="d-flex">
          <img src={logo} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={toggleExpand}
        >
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="ms-auto justify-content-between"
            defaultActiveKey="#home"
          >
            <Nav.Item>
              <Nav.Link as={Link} to="/redovisning" onClick={closeNav}>
                Redovisning
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/lonhr" onClick={closeNav}>
                Lön och HR
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/radgivning" onClick={closeNav}>
                Affärsrådgivning
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/interim" onClick={closeNav}>
                Interim
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={Link} to="/analys" onClick={closeNav}>
                Analys, strategi och beslut
              </Nav.Link>
            </Nav.Item>
            {size.width < 1200 && <Nav.Item>
              <Nav.Link as={Link} to="/vision" onClick={closeNav}>
                Vision
              </Nav.Link>
            </Nav.Item>}
            {size.width < 1200 && <Nav.Item>
              <Nav.Link as={Link} to="/auktoriserad" onClick={closeNav}>
                Auktoriserad byrå
              </Nav.Link>
            </Nav.Item>}
            { <Nav.Item>
              <Nav.Link as={Link} to="/kontakt" onClick={closeNav}>
                Kontakta oss
              </Nav.Link>
            </Nav.Item>}
            {/* <Button
              variant="info"
              size="lg"
              onClick={kontakt}
              style={{
                margin: "auto",
                borderRadius: "10px",
                color: "white",
                backgroundColor: "#00a39b",
              }}
            >
              Kontakta oss
            </Button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
