import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./aukt.css";

function AuktoContainer() {
  var Pic = require('../../assets/srf.png');
  var Pic2 = require('../../assets/rex.png');
  return (
    <Container className="aukt-content-container">
      <Col>
        <Row xs={1} sm={1} md={2} style={{justifyContent: "center"}}>
          <Col>
            <h2>Auktoriserad byrå</h2>
            <p>
              Redovisningsverksamheter som har minst en Srf Auktoriserad
              Redovisningskonsult per fem anställda kan profilera sig som Srf
              Auktoriserad byrå eller företag. Denna profilering signalerar rätt
              kompetens och god kvalitetsnivå på ett tydligt sätt.
              Auktorisationen skapar trygghet till de av landets företagare som
              är intresserade av att outsourca sin ekonomihantering.
            </p>
          </Col>
          <Col>
            <img src={Pic} alt="avatar" className="aukt-pic1" />
          </Col>
        </Row>
        <Row xs={1} sm={1} md={2} style={{justifyContent: "center"}}>
          <Col>
            <h3>Rex - Svensk standard för redovisningsuppdrag</h3>
            <p>
              Srf Auktoriserade Redovisningskonsulter® och redovisningsföretag
              arbetar enligt gällande branschstandard, Rex – Svensk standard för
              redovisningsuppdrag. Rex baseras på nordiska principer för
              redovisningstjänster och ersätter från januari 2018 den tidigare
              Reko-standarden. Rex tillämpas för att skapa hög kvalitet i
              uppdragen enligt konceptet ”rätt från början”.
            </p>
          </Col>
          <Col className="aukt-content-column">
            <img src={Pic2} alt="avatar" className="aukt-pic2" />
          </Col>
        </Row>
      </Col>
    </Container>
  );
}

export default AuktoContainer;
