import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./vision.css";
function VisionsContainer() {
  var Pic4 = require('../../assets/vision_logo.jpg');
  return (
    <Container className="radgivning-content-container">
      <Row xs={1} sm={1} md={2} style={{justifyContent: "center"}}>
        <Col >
          <h2>Vision</h2>
          <p>
            Vår vision är att vara det självklara valet av redovisningsbyrå för
            små -och medelstora bolag i tjänsteproducerande branscher. Att genom
            teamarbete, digitala lösningar och trygg närvaro erbjuda bästa
            möjliga service till dig som kund. För oss är det viktigt att hitta
            ett flöde i dina ekonomiska och administrativa processer så du kan
            lägga din tid på värdeskapande aktiviteter.
          </p>
          
        </Col>
        <Col>
        <img src={Pic4} alt="avatar" className="logga-vision"></img>
        </Col>
      </Row>
    </Container>
  );
}

export default VisionsContainer;
